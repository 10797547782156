* {
    margin: 0;
    padding: 0;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }
  
  /* Modal Button */
  .modalButton {
    position: fixed;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
    padding: 12px 24px;
  }
  
  
  /* Modal */
  .overlay {
    /* background-color: rgba(0, 0, 0, 0.5); */
    position: fixed;
    width: 100%;
    height: 100%;
  }
  
  .modalContainer {
    max-width: 600px;
    width: 100%;
    position: fixed;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    background-color: #ffffff;
    box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.75);
    border-radius: 8px;
  }
  

  .modalRight {
    width: 100%;
  }
  
  .closeBtn {
    position: fixed;
    top: 8px;
    right: 8px;
  }
  
  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    margin-top: 3rem;
    padding: 1rem 2rem;
  }
  
  .btnContainer {
    display: flex;
    padding: 1rem 1rem;
  }
  .btnContainer button {
    width: 100%;
    margin: .5rem;
    padding: 16px 0;
    /* border: none; */
    border: 1px solid #411b57;
    /* color: #ffffff; */
  }
  
  .btnPrimary {
    background-color: #411b57;
    color: white;
  }
  
  .btnOutline {
    /* background-color: #a76a99; */
    background-color: white;
    color: #411b57;
  }
  
  .bold {
    font-weight: 600;
  }
  
  @media screen and (max-width: 500px) {
    .modalContainer {
      flex-direction: column;
      top: 0;
      left: 0;
      transform: none;
      width: 100%;
      height: 100vh;
    }
    img {
      width: 100%;
      max-height: 70vh;
      object-fit: cover;
    }
  
    .heading {
      margin: 1rem;
    }
  }